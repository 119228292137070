import Chainable from "./Chainable.mts";
import { msIn } from "./Duration.mts";
import Locale from "./Locale.mts";
import Range from "./Range.mts";
import UNumber from "./UNumber.mts";
import UObject from "./UObject.mts";

const ordinality = (n: number) =>
  `${n}${
    (Math.floor(n / 10) !== 1 && ["st", "nd", "rd"][(n % 10) - 1]) || "th"
  }`;

const timeZoneOffsets = {
  UTC: 0,
  PST: -8,
};

type TimeZone = keyof typeof timeZoneOffsets;

declare global {
  interface Date {
    getUTCDate(): Range<1, 32>[number];
    getUTCDay(): Range<0, 7>[number];
    getUTCMonth(): Range<0, 12>[number];
    getDate(): Range<1, 32>[number];
    getDay(): Range<0, 7>[number];
    getMonth(): Range<0, 12>[number];
  }
}

namespace UDate {
  export namespace GlobalMod {
    export const Original = Date;

    export function reset() {
      globalThis.Date = Original;
    }

    export function shift(offsetMs: number) {
      // In case multiple offsets are stacked, we'll keep a local reference to each original
      const Original = Date;
      globalThis.Date = class extends Date {
        static now() {
          return Original.now() + offsetMs;
        }
        constructor(value = Date.now()) {
          super(value);
        }
        static [Symbol.hasInstance](value: Date) {
          return (
            Function.prototype[Symbol.hasInstance].call(Date, value) ||
            value instanceof Original
          );
        }
      } as typeof Date;
    }
  }

  export namespace mut {
    export const startOf = {
      day(date = new Date()) {
        date.setHours(0, 0, 0, 0);
        return date;
      },
      week(date = new Date()) {
        date.setDate(date.getDate() - date.getDay());
        return startOf.day(date);
      },
      month(date = new Date()) {
        date.setDate(1);
        return startOf.day(date);
      },
      year(date = new Date()) {
        date.setMonth(0);
        return startOf.month(date);
      },
    };
    export const add = {
      days(date: Date, days: number) {
        date.setDate(date.getDate() + days);
        return date;
      },
      weeks(date: Date, weeks: number) {
        date.setDate(date.getDate() + weeks * 7);
        return date;
      },
      months(date: Date, months: number) {
        const original = new Date(date);

        date.setMonth(date.getMonth() + months);

        // If day overflows, we'll fall back to the last day of the target month.
        // For example 01/31 01:23 + 1 month = 02/28 01:23 (or 02/29 01:23 on leap year)
        if (diff.months(date, original) > months) {
          date.setDate(1);
          add.days(date, -1);
        }

        return date;
      },
      years(date: Date, years: number) {
        date.setFullYear(date.getFullYear() + years);
        return date;
      },
    };
  }

  export const startOf = UObject.transform(mut.startOf, ([unit, fn]) => [
    unit,
    (date?: Date) => fn(date && new Date(date)),
  ]);

  export const add = UObject.transform(mut.add, ([unit, fn]) => [
    unit,
    (date: Date, amount: number) => fn(new Date(date), amount),
  ]);

  export function dayOfYear(date: Date) {
    const startOfYear = startOf.year(date);
    const diff =
      +date -
      +startOfYear +
      (startOfYear.getTimezoneOffset() - date.getTimezoneOffset()) *
        msIn.minute;
    return Math.floor(diff / msIn.day);
  }

  export const diff = {
    days(left: Date, right: Date) {
      const startOfLeft = startOf.day(left);
      const startOfRight = startOf.day(right);

      const timestampLeft =
        +startOfLeft - startOfLeft.getTimezoneOffset() * msIn.minute;
      const timestampRight =
        +startOfRight - startOfRight.getTimezoneOffset() * msIn.minute;

      // Round the number of days to the nearest integer because the number of
      // milliseconds in a day is not constant (e.g. it's different in the week
      // of the daylight saving time clock shift).
      return Math.round((timestampLeft - timestampRight) / msIn.day);
    },
    weeks(date: Date, other: Date) {
      return diff.days(date, other) / 7;
    },
    months(date: Date, other: Date) {
      return (
        (date.getFullYear() - other.getFullYear()) * 12 +
        date.getMonth() -
        other.getMonth()
      );
    },
    years(date: Date, other: Date) {
      return date.getFullYear() - other.getFullYear();
    },
  };

  const parsers = {
    "yyyy-mm-dd": (dateString) => ({
      year: +dateString.slice(0, 4),
      month: +dateString.slice(5, 7) - 1,
      date: +dateString.slice(8, 10),
    }),
    "mm/dd/yyyy": (dateString) => ({
      month: +dateString.slice(0, 2) - 1,
      date: +dateString.slice(3, 5),
      year: +dateString.slice(6, 10),
    }),
    "dd/mm/yyyy": (dateString) => ({
      date: +dateString.slice(0, 2),
      month: +dateString.slice(3, 5) - 1,
      year: +dateString.slice(6, 10),
    }),
  } satisfies Record<
    string,
    (dateString: string) => { year: number; month: number; date: number }
  >;
  export function parse(dateString: string, pattern: keyof typeof parsers) {
    const components = parsers[pattern](dateString);

    const date = new Date();

    date.setFullYear(components.year);
    date.setMonth(components.month);
    date.setDate(components.date);
    if (
      date.getFullYear() !== components.year ||
      date.getMonth() !== components.month ||
      date.getDate() !== components.date
    )
      date.setTime(NaN);

    return date;
  }

  export function isValid(date: Date) {
    return !isNaN(date.getTime());
  }

  export const format = (() => {
    const replacers = (() => {
      const makeReplacer =
        <T,>(transform: (input: Date) => T) =>
        (replacer: (input: T) => string) =>
        (input: Date, { zone = null as null | TimeZone } = {}) => {
          const offset = (() => {
            // getTimezoneOffset is in minutes
            if (!zone) return -input.getTimezoneOffset() * msIn.minute;

            const offsetHours = timeZoneOffsets[zone];
            if (offsetHours == null)
              throw new Error(
                `Invalid timezone "${zone}". Supported timzones include ${Object.keys(
                  timeZoneOffsets,
                ).join(", ")}`,
              );
            return offsetHours * msIn.hour;
          })();
          return replacer(transform(new Date(+input + offset)));
        };

      const Z = (input: Date, { zone = null as null | TimeZone } = {}) =>
        zone ||
        `GMT${UNumber.format
          .pad(2)
          .withSign(-(input.getTimezoneOffset() * msIn.minute) / msIn.hour)}`;

      const date = (() => {
        const d = makeReplacer((input) => input.getUTCDate());
        const w = makeReplacer((input) => input.getUTCDay());
        const m = makeReplacer((input) => input.getUTCMonth());
        const y = makeReplacer((input) => input.getUTCFullYear());

        const weekdayFormat =
          (format: NonNullable<Intl.DateTimeFormatOptions["weekday"]>) =>
          (n: number) =>
            new Intl.DateTimeFormat(Locale.current, {
              weekday: format,
            }).format(
              (() => {
                const date = startOf.week();
                // Account for daylight savings
                date.setHours(12);
                mut.add.days(date, n);

                return date;
              })(),
            );
        const monthFormat =
          (format: NonNullable<Intl.DateTimeFormatOptions["month"]>) =>
          (n: number) =>
            new Intl.DateTimeFormat(Locale.current, {
              month: format,
            }).format(
              (() => {
                const date = new Date();
                date.setMonth(n);
                return date;
              })(),
            );

        return {
          d: d((n) => `${n}`),
          dd: d((n) => `${n}`.padStart(2, "0")),
          ddd: d(ordinality),
          W: w((n) => weekdayFormat("short")(n)[0]!),
          Ww: w((n) => weekdayFormat("short")(n).slice(0, 2)),
          Www: w(weekdayFormat("short")),
          Wwww: w(weekdayFormat("long")),
          m: m((n) => `${n + 1}`),
          mm: m(monthFormat("2-digit")),
          Mmm: m(monthFormat("short")),
          Mmmm: m(monthFormat("long")),
          yy: y((n) => `${n}`.slice(-2)),
          yyyy: y((n) => `${n}`),
          Z,
        } as any;
      })();

      const time = (() => {
        const h = makeReplacer((input) => input.getUTCHours());
        const m = makeReplacer((input) => input.getUTCMinutes());
        const s = makeReplacer((input) => input.getUTCSeconds());
        const ms = makeReplacer((input) => input.getUTCMilliseconds());

        return {
          h: h((n) => `${n}`),
          hh: h((n) => `${n}`.padStart(2, "0")),
          "12h": h((n) => `${n % 12 || 12}`),
          "12hh": h((n) => `${n % 12 || 12}`.padStart(2, "0")),
          m: m((n) => `${n}`),
          mm: m((n) => `${n}`.padStart(2, "0")),
          s: s((n) => `${n}`),
          ss: s((n) => `${n}`.padStart(2, "0")),
          ms: ms((n) => `${n}`.slice(0, 1)),
          mss: ms((n) => `${n}`.slice(0, 2).padStart(2, "0")),
          msss: ms((n) => `${n}`.slice(0, 3).padStart(3, "0")),
          apm: h((n) => (n < 12 ? "am" : "pm")),
          Z,
        } as any;
      })();

      for (const replacerMap of [date, time] as any) {
        for (const [str, replacer] of Object.entries(replacerMap) as any) {
          replacerMap[str.toLowerCase()] ??= (input: any) =>
            `${replacer(input)}`.toLowerCase();
          replacerMap[str.toUpperCase()] ??= (input: any) =>
            `${replacer(input)}`.toUpperCase();
        }
      }

      return { date, time };
    })();

    const patternByType = {
      date: /d+|w+|m+|y+|z/gi,
      time: /ms+|(12)?h+|m+|s+|apm|z/gi,
    };
    type Type = keyof typeof patternByType;

    const withConfig =
      ({
        zone = null as TimeZone | null,
        defaultStr = "-",
        type = "date" as Type,
      } = {}) =>
      (
        date: ConstructorParameters<typeof Date>[0] | undefined | null,
        pattern: string,
      ) => {
        if (!date) return defaultStr;

        if (!(date instanceof Date)) date = new Date(date);
        if (zone) Object.assign(date, { zone });

        return pattern.replace(
          patternByType[type],
          (str) => replacers[type][str]?.(date, { zone }) || str,
        );
      };

    const chainableForType = <T extends typeof withConfig>(
      builder: T,
      type: Type,
    ) =>
      Chainable.create(
        builder,
        {
          default: (str: string) => ({ defaultStr: str }),
          zone: (zone: TimeZone) => ({ zone }),
          // Timezone shorthand, e.g. UDate.format.UTC
          ...(Object.fromEntries(
            Object.keys(timeZoneOffsets).map((key) => [key, { zone: key }]),
          ) as { [Zone in TimeZone]: { zone: Zone } }),
        },
        { type },
      );

    return chainableForType(
      Object.assign(withConfig, { time: chainableForType(withConfig, "time") }),
      "date",
    );
  })();

  export type Range = [from: Date, to: Date];
  export namespace Range {
    export const from = (
      resolvable: Range | { startAt: Date; endAt: Date },
    ): Range =>
      Array.isArray(resolvable)
        ? resolvable
        : [resolvable.startAt, resolvable.endAt];
    export const format = (range: Range, pattern: string) =>
      `${UDate.format(range[0], pattern)} - ${UDate.format(range[1], pattern)}`;
  }

  export namespace Relative {
    export function format(date: Date | undefined, referenceDate = new Date()) {
      if (!date) return "-";

      return new Intl.RelativeTimeFormat(Locale.current, {
        style: "long",
        numeric: "auto",
      }).format(
        ...((): [number, Intl.RelativeTimeFormatUnit] => {
          const days = UDate.diff.days(date, referenceDate);
          if (Math.abs(days) <= 30) return [days, "day"];

          const months = UDate.diff.months(date, referenceDate);
          if (Math.abs(months) <= 12) return [months, "month"];

          const years = UDate.diff.years(date, referenceDate);
          return [years, "year"];
        })(),
      );
    }
  }
}

export default UDate;
